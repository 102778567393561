<template>
  <Dashboard title="Profile Page">
    <div v-if="loading" class="flex justify-center py-10">
      <svg class="w-16 h-16 text-white animate-spin" fill="none" viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
    <div v-else class="flex flex-col w-full gap-12">
      <div class="flex gap-6 w-full flex-col lg:flex-row">
        <div class="flex flex-col flex-[8] rounded-lg overflow-hidden bg-gray-900">
          <div
            class="h-[300px] w-full bg-cover bg-center bg-no-repeat"
            :style="{ backgroundImage: `url(${bannerImg})` }"
          ></div>
          <div class="flex px-6 py-[28px] gap-4 items-center">
            <img
              v-if="user?.steam_profile"
              class="w-[72px] h-[72px] rounded-full"
              :src="user?.steam_profile?.avatarfull"
              alt="User avatar"
            />
            <img
              v-else
              class="w-[72px] h-[72px] rounded-full"
              src="../assets/logos/refrag-icon-button.svg"
              alt="User avatar"
            />
            <div class="flex flex-col gap-2 justify-center flex-1">
              <div v-if="!usernameEditing" class="flex gap-2 items-center">
                <h1 class="text-text-primary text-3xl font-semibold">
                  {{ getUsername() }}
                </h1>
                <button
                  v-if="canEdit()"
                  @click="editUsername"
                  class="inline-flex px-2 py-1 mr-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  <span>Edit</span>
                </button>
              </div>
              <div
                v-else
                class="mt-1 text-sm text-text-secondary sm:mt-0 sm:col-span-2 w-1/2 flex items-center"
              >
                <input
                  class="block w-full mr-4 rounded-md bg-gray-800 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                  type="text"
                  v-model="username"
                />
                <button
                  @click="submitUsername"
                  class="inline-flex px-2 py-1 mr-4 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  <span>Save</span>
                </button>
                <button
                  @click="editUsername"
                  class="inline-flex px-2 py-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  <span>Cancel</span>
                </button>
              </div>
              <div :class="getRoleColor()" class="text-sm">{{ getRole() }}</div>
            </div>
            <a
              v-if="user?.steam_profile"
              :href="user?.steam_profile?.profileurl"
              target="_blank"
              class="cursor-pointer text-text-primary bg-gray-800 px-3 py-2.5 rounded-lg gap-1 flex items-center hover:bg-gray-700 duration-300"
            >
              <img
                src="../assets/icons/steam-icon.svg"
                class="h-3 w-3"
                alt="Steam icon"
              />
              <h1 class="text-xs">Steam</h1>
              <img
                src="../assets/icons/open-arrow.svg"
                class="h-3 w-3"
                alt="External link icon"
              />
            </a>
            <a
              v-if="user?.faceit_nickname"
              :href="`https://www.faceit.com/en/players/${user?.faceit_nickname}`"
              target="_blank"
              class="cursor-pointer text-text-primary bg-gray-800 px-3 py-2.5 rounded-lg gap-1 flex items-center hover:bg-gray-700 duration-300"
            >
              <img
                src="../assets/icons/faceit-icon.svg"
                class="h-3 w-3"
                alt="Steam icon"
              />
              <h1 class="text-xs">Faceit</h1>
              <img
                src="../assets/icons/open-arrow.svg"
                class="h-3 w-3"
                alt="External link icon"
              />
            </a>
          </div>
        </div>
        <div class="flex-[4] rounded-lg overflow-hidden bg-gray-900 flex flex-col">
          <h1
            class="text-text-primary p-6 text-xl font-semibold border-b-2 border-gray-950"
          >
            Badges Display
          </h1>
          <div class="w-full p-6 flex gap-4 relative border-b-2 border-gray-950">
            <div class="w-full absolute top-0 left-0 h-full z-20 topography-overlay">

            </div>
            <div class="w-full absolute top-0 left-0 h-full first-background z-10">

            </div>
            <img
              v-if="badges.length > 0"
              :src="`/img/icons/${badges[0]?.name}_badge.svg`"
              class="h-24 w-24 min-w-[96px] z-50"
              alt="Medal icon"
            />
            <div class="flex flex-col gap-2 justify-center z-50">
              <h1 class="text-text-primary text-medium">
                {{ formatBadgeName(badges[0]?.name) }}
              </h1>

              <h2 class="text-text-secondary text-sm font-light">
                {{ badges[0]?.description }}
              </h2>

            </div>
          </div>
          <div class="flex p-6 flex-wrap">
            <img
              v-for="badge in badges.slice(1)"
              :src="`/img/icons/${badge.name}_badge.svg`"
              class="h-16 w-16 min-w-[64px]"
              alt="Medal icon"
            />
          </div>
        </div>
      </div>

      <!-- nav -->
      <div class="">
        <nav class="w-full flex flex-row relative gap-9 border-b border-gray-800">
          <div
            ref="overviewDiv"
            class="py-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start"
            @click="updateActiveTab('overview')"
          >
            <img
              src="../assets/icons/info-icon.svg"
              :class="[activeTab === 'overview' ? 'brightness-200' : '']"
              class="h-5 w-5 duration-300"
              alt="Info icon"
            />
            <h3
              class="leading-5 duration-300"
              :class="[
                activeTab === 'overview' ? 'text-text-primary' : 'text-text-secondary',
              ]"
            >
              Overview
            </h3>
          </div>
          <div
            ref="statsDiv"
            class="py-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start"
            @click="updateActiveTab('statistics')"
          >
            <img
              src="../assets/icons/stats-icon.svg"
              :class="[activeTab === 'statistics' ? 'brightness-200' : '']"
              class="h-5 w-5 duration-300"
              alt="Stats icon"
            />
            <h3
              class="leading-5 duration-300"
              :class="[
                activeTab === 'statistics' ? 'text-text-primary' : 'text-text-secondary',
              ]"
            >
              Statistics
            </h3>
          </div>
          <div
            ref="badgesDiv"
            class="py-4 text-center cursor-pointer flex gap-2 items-center"
            @click="updateActiveTab('badges')"
          >
            <img
              src="../assets/icons/badge-icon.svg"
              :class="[activeTab === 'badges' ? 'brightness-200' : '']"
              class="h-5 w-5 duration-300"
              alt="Badge icon" />
            <h3
              class="leading-5 duration-300"
              :class="[activeTab === 'badges' ? 'text-text-primary' : 'text-text-secondary']">
              Badges
            </h3>
          </div>
          <div
            ref="communityDiv"
            class="py-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start"
            @click="updateActiveTab('community_content')"
          >
            <img
              src="../assets/icons/globe.svg"
              :class="[activeTab === 'community_content' ? 'brightness-200' : '']"
              class="h-5 w-5 duration-300"
              alt="Stats icon"
            />
            <h3
              class="leading-5 duration-300"
              :class="[
                activeTab === 'community_content'
                  ? 'text-text-primary'
                  : 'text-text-secondary',
              ]"
            >
              Community Content
            </h3>
          </div>
          <div class="absolute flex-row md:flex bottom-0">
            <div
              class="h-[2px] grow-transition"
              :style="{ width: getSectionUnderlinePadding() + 'px' }"
            />
            <div
              ref="sectionUnderline"
              class="h-[2px] bg-primary-600 grow-transition"
              :style="{ width: getSectionUnderlineWidth() + 'px' }"
            />
          </div>
        </nav>
      </div>
      <div v-if="activeTab === 'overview'" class="flex w-full flex-col gap-6">
        <div class="flex flex-col gap-6 w-full" v-if="user">
          <h1 class="text-text-primary font-semibold text-[24px]">Statistics</h1>
          <div class="flex gap-6 w-full flex-col lg:flex-row">
            <div
              @click="updateEloGameType('crossfire')"
              :class="[eloGameType === 'crossfire' ? 'outline-primary-500 outline' : '']"
              class="flex flex-1 py-2 items-center justify-center rounded-lg relative bg-gray-900 cursor-pointer duration-300"
            >
              <DoughnutChart
                class="w-[208px] h-[208px]"
                :chartData="getCrossfireData"
                :options="doughnutOptions"
              />
              <div
                class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center mt-3"
              >
                <Pill
                  v-if="crossfireRankPercentage() > 0"
                  class="px-2 py-[2px] mb-3"
                  :text="getCrossfireRankPercentageString()"
                  :bgColor="`${getPercentageColor(crossfireRankPercentage())}30`"
                  :textColor="`${getPercentageColor(crossfireRankPercentage())}`"
                />
                <h2 class="text-text-secondary">Crossfire ELO</h2>
                <h1 class="text-text-primary font-semibold text-4xl">
                  {{ crossfireElo() }}
                </h1>
              </div>
            </div>
            <div
              @click="updateEloGameType('prefire')"
              :class="[eloGameType === 'prefire' ? 'outline-primary-500 outline' : '']"
              class="flex flex-1 py-2 items-center justify-center rounded-lg relative cursor-pointer duration-300 bg-gray-900"
            >
              <DoughnutChart
                class="w-[208px] h-[208px]"
                :chartData="getPrefireData"
                :options="doughnutOptions"
              />
              <div
                class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center mt-3"
              >
                <Pill
                  v-if="prefireRankPercentage() > 0"
                  class="px-2 py-[2px] mb-3"
                  :text="getPrefireRankPercentageString()"
                  :bgColor="`${getPercentageColor(prefireRankPercentage())}30`"
                  :textColor="`${getPercentageColor(prefireRankPercentage())}`"
                />
                <h2 class="text-text-secondary">Prefire ELO</h2>
                <h1 class="text-text-primary font-semibold text-4xl">
                  {{ prefireElo() }}
                </h1>
              </div>
            </div>
            <div
              class="flex flex-1 bg-gray-900 rounded-lg flex-col divide-y-2 divide-gray-950"
            >
              <div class="px-6 py-4 flex items-center gap-2">
                <h2 class="text-text-secondary flex-1">Headshot %</h2>
                <h1 class="text-text-primary font-medium text-xl">
                  {{ averageHeadshotPercentage }}%
                </h1>
              </div>
              <div class="px-6 py-4 flex">
                <h2 class="text-text-secondary flex-1">Time to Kill</h2>
                <h1 class="text-text-primary font-medium text-xl">
                  {{ averageTimeToKill }}s
                </h1>
              </div>
              <div class="px-6 py-4 flex">
                <h2 class="text-text-secondary flex-1">Crosshair Placement</h2>
                <h1 class="text-text-primary font-medium text-xl">
                  {{ averageCrosshairPlacement }}°
                </h1>
              </div>
              <div class="px-6 py-4 flex">
                <h2 class="text-text-secondary flex-1">Counter Strafing</h2>
                <h1 class="text-text-primary font-medium text-xl">
                  {{ averageCounterStrafe }}%
                </h1>
              </div>
            </div>
          </div>
          <div class="bg-gray-900 rounded-lg p-6">
            <LineChart :options="eloOverTimeOptions" :chartData="getEloOverTimeData" />
          </div>
        </div>

        <div class="flex w-full gap-4 items-center">
          <h1 class="text-text-primary font-semibold text-[24px] flex-1">Match History</h1>
          <svg
            v-if="loadingMatches"
            width="16"
            height="16"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="animate-spin"
          >
            <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
            <path
              d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
              stroke="#4E54C8"
              stroke-width="2"
              />
          </svg>            
          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Map:</span>
                <span v-if="selectedMap">{{ selectedMap.friendly_name }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedMap(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedMap === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Maps
                      <CheckIcon v-if="selectedMap === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="map"
                    v-for="map in cs_maps"
                    v-slot="{ active }"
                    @click="(e) => { e.preventDefault(); updateSelectedMap(map); }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ map.friendly_name }}
                      <CheckIcon v-if="map.name === selectedMap" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Platform:</span>
                <span v-if="selectedPlatform">{{ selectedPlatform }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Platforms
                      <CheckIcon v-if="selectedPlatform === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform('Valve'); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === 'Valve' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      Valve
                      <CheckIcon v-if="selectedPlatform === 'Valve'" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform('Faceit'); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === 'Faceit' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      Faceit
                      <CheckIcon v-if="selectedPlatform === 'Faceit'" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Match Type:</span>
                <span v-if="selectedMatchType">{{ selectedMatchType }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedMatchType(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedMatchType === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Match Types
                      <CheckIcon v-if="selectedMatchType === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="type"
                    v-for="type in match_types"
                    v-slot="{ active }"
                    @click="(e) => { e.preventDefault(); updateSelectedMatchType(type); }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ type }}
                      <CheckIcon v-if="type === selectedMatchType" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>


        <div class="w-full flex flex-col gap-[2px] rounded-lg overflow-hidden">
          <div
            :class="[ userMatchHistory.length > 0 ? 'px-4 py-2' : 'p-4']"
            class="bg-gray-900 text-text-primary text-xs tracking-[1.5px] flex-1 uppercase flex md:gap-2 items-center"
          >
            <div class="flex-1 md:flex-none">Last {{ userMatchHistory.length }} Matches</div>
            <span v-if="userMatchHistory.length > 0" class="hidden md:block text-text-secondary">•</span>
            <span
              v-if="userMatchHistory.length > 0"
              class="hidden md:block"
              :class="{
                'text-success-400': userRecentStats?.win_rate > 50,
                'text-text-primary': userRecentStats?.win_rate == 50,
                'text-danger-400': userRecentStats?.win_rate < 50,
              }"
            >
              {{ userRecentStats?.win_rate }}%
            </span>
            <div class="flex-1 flex items-center" v-if="userMatchHistory.length > 0">
              <h1 class="flex-1 hidden md:block">Win Ratio</h1>
              <div class="flex items-center justify-end gap-2 mr-2">
                <button 
                  class="p-2 text-text-secondary hover:text-text-primary disabled:opacity-50 disabled:hover:text-text-secondary"
                  :disabled="currentPage === 1"
                  @click="changePage(currentPage - 1)"
                >
                <div class="flex bg-gray-700 p-2 rounded-lg">
                  <ArrowLeftIcon class="h-4 w-4 text-text-secondary"></ArrowLeftIcon>
                </div>
                  
                </button>

                <button
                  v-for="page in displayedPages" 
                  :key="page"
                  @click="changePage(page)"
                  class="px-3 py-1 rounded-md text-sm"
                  :class="[
                    currentPage === page 
                      ? 'text-primary-500' 
                      : 'text-text-secondary hover:text-text-primary'
                  ]"
                >
                  {{ page }}
                </button>

                <span v-if="hasEllipsis" class="text-text-secondary">...</span>
                
                <button
                  v-if="showLastPage && !displayedPages.includes(totalPageCount)"
                  @click="changePage(totalPageCount)"
                  class="px-3 py-1 rounded-md text-sm text-text-secondary hover:text-text-primary"
                  :class="{ 'bg-primary-600 text-text-primary': currentPage === totalPageCount }"
                >
                  {{ totalPageCount }}
                </button>

                <button 
                  class="p-2 text-text-secondary hover:text-text-primary disabled:opacity-50 disabled:hover:text-text-secondary"
                  :disabled="currentPage === totalPageCount"
                  @click="changePage(currentPage + 1)"
                >
                  <div class="flex bg-gray-700 p-2 rounded-lg">
                    <ArrowRightIcon class="h-4 w-4 text-text-secondary"></ArrowRightIcon>
                  </div>
                </button>
              </div>
            </div>
            
            <div v-else class="flex-1 hidden md:block"></div>
          </div>

          <div v-if="userMatchHistory.length > 0" v-for="match in userMatchHistory" class="bg-gray-900">
            <MatchRow :match="match" />
          </div>

          <div
            v-else
            class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px]"
          >
            No Matches Found, make sure to add your Faceit ID and Steam tokens to your
            account in
            <router-link to="/settings" class="underline">settings!</router-link>
          </div>
        </div>
      </div>
      <Statistics
        v-else-if="activeTab === 'statistics' && user.access_level > 1"
        :statistics="userStats"
        @updateMod="updateUserStatsMod"
        @updateTimeFrame="updateStatsTimeFrame"
      />
      <div
        v-else-if="activeTab === 'statistics' && user.access_level < 2"
        class="flex w-full flex-col gap-12"
      >
        <div class="flex flex-col gap-6 items-center">
          <h1 class="text-text-primary font-semibold text-[24px]">Statistics</h1>
          <div class="flex flex-col gap-6">
            <h2 class="text-text-secondary text-lg">
              You need to upgrade to our Competitor Tier to access statistics
            </h2>
            <router-link
              to="/upgrade"
              class="bg-primary-600 px-4 py-3 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
            >
              Upgrade Now
              <img class="brightness-200" src="../assets/icons/rightarrow.svg" />
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'badges'"
        class="flex w-full flex-col gap-6"
      >
        <h1 class="text-text-primary font-semibold text-[24px]">Badges List</h1>

        <div class="flex flex-col gap-6">
          <div
            ref="badgesDiv"
            class="flex w-full flex-wra flex-col"
          >
            <div
              v-for="badgeGroup in badgesGroupedByCompleted"
              class="flex flex-col"
            >
              <h1 class="text-text-primary font-semibold text-[18px] flex items-center gap-2">
                • <span class="text-text-secondary text-sm font-light">Unlocked {{ moment(badgeGroup[0].created_at).format("MMMM Do, YYYY") }}</span>
              </h1>
              <div class="grid grid-cols-1 lg:grid-cols-2 border-l border-gray-800 ml-[3px] px-4 py-2 gap-6">
                <div
                  v-for="badge in badgeGroup"
                  class="flex items-center cols-span-1 bg-gray-900 rounded-lg p-4 gap-6"
                >
                  <img
                    :src="`/img/icons/${badge.name}_badge.svg`"
                    class="h-16 w-16 min-w-[64px]"
                    alt="Medal icon"
                  />
                  <div class="flex flex-col gap-2">
                    <h2 class="text-text-primary">
                      {{ formatBadgeName(badge.name) }}
                    </h2>
                    <h2 class="text-text-secondary text-sm">
                      {{ badge.description }}
                    </h2>
                  </div>
  
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'community_content'"
        class="flex w-full flex-col gap-12"
      >
        <div class="flex flex-col gap-6">
          <h1 class="text-text-primary font-semibold text-[24px]">Creator Studio</h1>
          <div class="flex flex-col gap-1 lg:gap-[2px] rounded-lg w-full overflow-hidden">
            <ContentRow
              ref="contentRow"
              :content="content"
              modUrl="custom_sets"
              activeMod="Creator Studio"
              :activeServer="activeServer"
              :isSubscribed="isSubscribedSet(content.id)"
              @updateServerInfo="updateServerInfo"
              @loadContent="getSubscribedSets"
              v-for="content in user.custom_sets.slice(0, 5)"
              :key="content.id"
            />
          </div>
          <h1 class="text-text-primary font-semibold text-[24px]">Grenade Groups</h1>
          <div class="flex flex-col gap-1 lg:gap-[2px] rounded-lg w-full overflow-hidden">
            <ContentRow
              ref="contentRow"
              :content="content"
              modUrl="grenade_groups"
              activeMod="Grenade Groups"
              :activeServer="activeServer"
              :isSubscribed="isSubscribedGrenadeGroup(content.id)"
              @updateServerInfo="updateServerInfo"
              @loadContent="getSubscribedGrenadeGroups"
              v-for="content in user.grenade_groups.slice(0, 5)"
              :key="content.id"
            />
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    @close="showServerModal = false"
  />
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted } from "vue";
import { DoughnutChart, LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import Pill from "../components/Pill.vue";
import axios from "axios";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { useRouter, useRoute } from "vue-router";
import ContentRow from "../components/CommunityHub/ContentRow.vue";
import { useStore } from "vuex";
import moment from "moment";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import refragBackground from "../assets/refrag-logo-background.png";
import Statistics from "../components/UserProfile/Statistics.vue";
import StartServerModal from "../components/StartServerModal.vue";
import { useHead } from "@unhead/vue";
import { forEach } from "jszip";
import MatchRow from "../components/UserProfile/MatchRow.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/outline";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/solid";

const route = useRoute();
const router = useRouter();
const store = useStore();
const url = ref(location.host);
const auth = useAuth();

const loading = ref(false);
const bodyLoading = ref(true);
const activeTeam = ref(store.getters.activeTeam);
const user = ref({});
const currentUser = auth.user();
const file = ref(null);
const fileURL = ref(null);
const bannerImg = ref(null);
const uploadImageModalOpen = ref(false);
const username = ref(null);
const usernameEditing = ref(false);
const userStats = ref();
const userStatsMod = ref("");
const eloGameType = ref("crossfire");
const userStatsTimeFrame = ref("30");
const showStats = ref(false);
const activeTab = ref("overview");
const overviewDiv = ref();
const statsDiv = ref();
const badgesDiv = ref();
const communityDiv = ref();
const sectionUnderline = ref(null);
const sectionUnderlineWidth = ref(92);
const sectionUnderlinePadding = ref(0);
const modsWithElo = computed(() => store.state.modInfo.mods.filter((mod) => mod.has_elo));
const serverInfo = ref();
const showServerModal = ref(false);
const activeServer = computed(() => store.getters.activeServer);
const userMatchHistory = ref([]);
const userRecentStats = ref({});
const selectedMap = ref('');
const selectedPlatform = ref('');
const selectedMatchType = ref('');
const loadingMatches = ref(false);
const totalMatchesCount = ref(0);
const totalPageCount = ref(0);
const currentPage = ref(1);
const maxVisiblePages = ref(5);

useHead({
  title: () => `Refrag | ${getUsername()} Profile`,
});

Chart.register(...registerables);

const handleResize = () => {
  updateUnderlineStyle();
};

// Add event listener on mount
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

// Remove event listener on unmount
onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const doughnutOptions = {
  rotation: 242, // start angle in degrees
  circumference: 240, // sweep angle in degrees
  borderWidth: 0,
  backgroundColor: ["#1E2733"],
  radius: "100%",
  cutout: "90%",
};

let eloOverTimeOptions = {
  spanGaps: true,
  plugins: {
    legend: {
      labels: {
        color: "#9CADC4",
      },
    },
  },
  scales: {
    x: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
      },
      ticks: {
        color: "#9CADC4",
      },
    },
    y: {
      suggestedMin: 0,
      grid: {
        borderDash: [5, 5],
        color: "#1E2733",
        borderColor: "transparent",
      },
      ticks: {
        color: "#9CADC4",
      },
    },
  },
};

function updateServerInfo(server) {
  serverInfo.value = server;
  showServerModal.value = true;
}

const getCrossfireData = computed(() => {
  return {
    datasets: [
      {
        data: [crossfireRankPercentage(), 1 - crossfireRankPercentage()],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return context.dataIndex === 0
            ? getPercentageRankGradient(ctx, chartArea, crossfireRankPercentage())
            : "#1E2733";
        },
      },
    ],
  };
});

const badges = computed(() => {
  var badges = user.value?.badges

  badges.forEach((badge) => {
    user.value.sashes.forEach((sash) => {
      if (sash.badge_id === badge.id) {
        badge.created_at = sash.created_at;
      }
    });
  });

  return badges.filter(
    (badge) => !badge.name.includes("member") && !badge.name.includes("community-hub")
  ).sort((a, b) => b.created_at - a.created_at);
});

const badgesGroupedByCompleted = computed(() => {
  const grouped = badges.value.reduce((groups, badge) => {
    const date = new Date(badge.created_at).toISOString().split('T')[0]
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(badge);
    return groups;
  }, {});

  return Object.values(grouped); // Convert the grouped object to an array of arrays
});

const getPrefireData = computed(() => {
  return {
    datasets: [
      {
        data: [prefireRankPercentage(), 1 - prefireRankPercentage()],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return context.dataIndex === 0
            ? getPercentageRankGradient(ctx, chartArea, prefireRankPercentage())
            : "#1E2733";
        },
      },
    ],
  };
});

const getEloOverTimeData = computed(() => {
  return {
    labels: selectedModEloTimeLabels.value,
    datasets: [
      {
        label: "ELO",
        data: selectedModEloOverTime.value,
        color: "white",
        fill: true,
        backgroundColor: "#6B70D115",
        borderColor: "#6B70D1",
        tension: 0.1,
      },
    ],
  };
});

function formatBadgeName(name) {
  if (!name) return;
  return name
    .split(/[-_]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function getPercentageColor(percent) {
  switch (true) {
    case percent >= 0.9:
      return "#FFD700";
    case percent >= 0.8:
      return "#EB4A49";
    case percent >= 0.6:
      return "#F453FB";
    case percent >= 0.4:
      return "#6A7DFF";
    case percent >= 0.2:
      return "#85B5E5";
    default:
      return "#B8C7D7";
  }
}

function updateSelectedMap(value) {
  selectedMap.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

function updateSelectedPlatform(value) {
  if (selectedPlatform.value !== value) {
    selectedMatchType.value = '';
  }
  selectedPlatform.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

function updateSelectedMatchType(value) {
  selectedMatchType.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

const cs_maps = computed(() => {
  return store.state.modInfo?.mods?.find(mod => 
    mod?.name?.toLowerCase() === 'nadr'
  )?.cs_maps?.filter(map => 
    map.cs2 === true
  )?.sort((a, b) => 
    a.friendly_name?.toLowerCase().localeCompare(b.friendly_name.toLowerCase())
  );
});

const match_types = computed(() => {
  if (selectedPlatform.value === 'Valve') {
    return [
      "Competitive",
      "Premier", 
      "Wingman"
    ];
  }

  if (selectedPlatform.value === 'Faceit') {
    return [
      "Matchmaking",
      "Championship",
    ]
  }
});

function getPercentageRankGradient(ctx, chartArea, percent) {
  let startAngle = (-45 * Math.PI) / 180;
  var gradient = ctx.createConicGradient(
    startAngle,
    chartArea.width / 2,
    chartArea.height / 2
  );
  if (percent >= 0.9) {
    gradient.addColorStop(0, "#FFD700");
    gradient.addColorStop(0.5, "#FFD70070");
    gradient.addColorStop(1, "#FFD700");
    return gradient;
  }
  if (percent >= 0.8) {
    gradient.addColorStop(0, "#EB4A49");
    gradient.addColorStop(0.5, "#EB4A4970");
    gradient.addColorStop(1, "#EB4A49");
    return gradient;
  }
  if (percent >= 0.6) {
    gradient.addColorStop(0, "#F453FB");
    gradient.addColorStop(0.5, "#F453FB70");
    gradient.addColorStop(1, "#F453FB");
    return gradient;
  }
  if (percent >= 0.4) {
    gradient.addColorStop(0, "#6A7DFF");
    gradient.addColorStop(0.5, "#6A7DFF70");
    gradient.addColorStop(1, "#6A7DFF");
    return gradient;
  }
  if (percent >= 0.2) {
    gradient.addColorStop(0, "#85B5E5");
    gradient.addColorStop(0.5, "#85B5E570");
    gradient.addColorStop(1, "#85B5E5");
    return gradient;
  }
  gradient.addColorStop(0, "#B8C7D7");
  gradient.addColorStop(0.5, "#B8C7D770");
  gradient.addColorStop(1, "#B8C7D7");
  return gradient;
}

function toggleUploadImageModal() {
  uploadImageModalOpen.value = !uploadImageModalOpen.value;
}

function getUser() {
  loading.value = true;
  axios({
    url: `users/${route.params.uuid}`,
    method: "GET",
    params: {
      elo_history_count: 10,
    },
  }).then((response) => {
    if (response.data.data.banner_file) {
      bannerImg.value = response.data.data.banner_file;
    } else {
      bannerImg.value = refragBackground;
    }
    user.value = response.data.data;
    username.value = user.value?.username;
    loading.value = false;
    showStats.value = true;
    getSubscribedSets();
    getSubscribedGrenadeGroups();
    getPlayerMatchHistory();
    if (user.value?.access_level > 1) {
      getUserStats();
    }
  });
}

function getUserStats() {
  bodyLoading.value = true;
  axios({
    url: `statistics/index_specific_user`,
    method: "GET",
    params: {
      "filters[game_type]": userStatsMod.value,
      "filters[last_x_days]": userStatsTimeFrame.value,
      elo_mod_id: getEloGameTypeId(),
      user_id: user.value?.uuid,
    },
  }).then((response) => {
    response.data.statistics.reverse();
    userStats.value = response.data;
  });
}

const selectedModEloOverTime = computed(() => {
  let elos = user.value?.elo_history?.find(
    (history) => history.mod.toLowerCase() === eloGameType.value.toLowerCase()
  )?.elos;
  return elos?.map((elo) => elo.score).reverse() || [];
});

const selectedModEloTimeLabels = computed(() => {
  let elos = user.value?.elo_history?.find(
    (history) => history.mod.toLowerCase() === eloGameType.value.toLowerCase()
  )?.elos;
  return elos?.map((elo) => moment(elo.created_at).format("LLL")).reverse() || [];
});

const averageCrosshairPlacement = computed(() => {
  return user.value?.average_crosshair_drift?.toFixed(2);
});

const averageCounterStrafe = computed(() => {
  return user.value?.average_counter_strafe?.toFixed(1);
});

const averageHeadshotPercentage = computed(() => {
  return user.value?.average_headshot_percentage?.toFixed(1);
});

const averageTimeToKill = computed(() => {
  return user.value?.average_time_to_kill?.toFixed(3);
});

function getEloGameTypeId() {
  return modsWithElo.value.find(
    (mod) => mod.name.toLowerCase() === eloGameType.value.toLowerCase()
  )?.id;
}

function updateEloGameType(value) {
  eloGameType.value = value;
}

function updateUserStatsMod(value) {
  userStatsMod.value = value;
  getUserStats();
}

function updateStatsTimeFrame(value) {
  userStatsTimeFrame.value = value;
  getUserStats();
}

function submitUsername() {
  axios({
    url: "users/update_user",
    method: "PUT",
    params: {
      username: username.value,
    },
  }).then((response) => {
    editUsername();
  });
}

function updateActiveTab(value) {
  activeTab.value = value;
  updateUnderlineStyle();
}

function updateUnderlineStyle() {
  if (activeTab.value === "statistics") {
    sectionUnderlineWidth.value = statsDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = statsDiv.value?.offsetLeft;
  } else if (activeTab.value === "community_content") {
    sectionUnderlineWidth.value = communityDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = communityDiv.value?.offsetLeft;
  } else if (activeTab.value === "overview") {
    sectionUnderlineWidth.value = overviewDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = overviewDiv.value?.offsetLeft;
  } else if (activeTab.value === "badges") {
    sectionUnderlineWidth.value = badgesDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = badgesDiv.value?.offsetLeft;
  }
}

function getSectionUnderlineWidth() {
  return sectionUnderlineWidth.value;
}

function getSectionUnderlinePadding() {
  return sectionUnderlinePadding.value;
}

function canEdit() {
  return (
    currentUser.uuid === user.value?.uuid || currentUser.username === user.value?.username
  );
}

function getPlayerMatchHistory() {
  loadingMatches.value = true;

  axios.get(`/matches/recent_match_stats`, {
    params: {
      'steam_id': user.value?.steam_profile?.steamid,
      'match_type': selectedMatchType.value.toLowerCase(),
      'platform': selectedPlatform.value.toLowerCase(),
      'map_name': selectedMap.value?.name?.toLowerCase() || '',
      'page': currentPage.value
    },
  }).then((response) => {
    userMatchHistory.value = response.data.matches;
    totalMatchesCount.value = response.data.total_count;
    totalPageCount.value = response.data.page_count;
    calculateRecentStats();
    loadingMatches.value = false;
  }).catch((error) => {
    userMatchHistory.value = [];
    loadingMatches.value = false;
  });
}

function calculateRecentStats() {
  const totalMatches = userMatchHistory.value.length;
  if (totalMatches === 0) return;

  const wins = userMatchHistory.value.filter(match => match.match_result === 1).length;
  const losses = userMatchHistory.value.filter(match => match.match_result === 0).length;
  const ties = userMatchHistory.value.filter(match => match.match_result === 2).length;

  userRecentStats.value = {
    win_rate: ((wins / totalMatches) * 100).toFixed(0),
    loss_rate: ((losses / totalMatches) * 100).toFixed(0),
    tie_rate: ((ties / totalMatches) * 100).toFixed(0),
  };
}

function getSubscribedSets(showLoad = true) {
  if (!activeTeam.value) return;

  if (showLoad) bodyLoading.value = true;

  axios({
    url: "/custom_sets/subscribed_to_sets",
    method: "GET",
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    store.state.subscribedToSetsIds = response.data;
    bodyLoading.value = false;
  });
}

function getSubscribedGrenadeGroups(showLoad = true) {
  if (!activeTeam.value) return;

  if (showLoad) bodyLoading.value = true;

  axios({
    url: "/grenade_groups/subscribed_to_grenade_groups",
    method: "GET",
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    store.state.subscribedToGrenadeGroupsIds = response.data;
    bodyLoading.value = false;
  });
}

function editUsername() {
  usernameEditing.value = !usernameEditing.value;
}

function getUsername() {
  if (username.value) {
    return username.value;
  }
  return user.value?.steam_profile?.personaname;
}

function isSubscribedSet(SetId) {
  return store.state.subscribedToSetsIds.some((e) => e.id === SetId);
}

function isSubscribedGrenadeGroup(groupId) {
  return store.state.subscribedToGrenadeGroupsIds.some((e) => e.id === groupId);
}

function scrollToDiv(id) {
  if (document.getElementById(id)) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }
}

function loadHashValue() {
  if (route.hash === "#custom_sets") {
    updateActiveTab("community_content");
    scrollToDiv("custom_sets");
  } else if (route.hash === "#grenade_groups") {
    updateActiveTab("community_content");
    scrollToDiv("grenade_groups");
  }
}

function copySucceeded() {
  store.commit("showMessage", {
    messageType: "success",
    messageText: "Link Copied to Clipboard",
  });
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
  return;
}

function getRoleColor() {
  switch (user.value?.access_level) {
    case 5:
      return "text-red-400";
    case 4:
      return "text-yellow-400";
    case 3:
      return "text-green-400";
    case 2:
      return "text-blue-400";
    case 1:
      return "text-indigo-400";
    default:
      return "text-gray-400";
  }
}

function getRole() {
  switch (user.value?.access_level) {
    case 5:
      return "Admin";
    case 4:
      return "Pro Tier";
    case 3:
      return "Team Tier";
    case 2:
      return "Competitor Tier";
    case 1:
      return "Player Tier";
    default:
      return "Free User";
  }
}

function crossfireElo() {
  const elos = user.value?.elos.filter((elo) => elo.mod.toLowerCase() == "crossfire");
  if (!elos.length) return 0;

  return elos[0].elo;
}

function crossfireRankPercentage() {
  const elos = user.value?.elo_ranks_percentage.filter(
    (elo) => elo.mod.toLowerCase() == "crossfire"
  );
  if (!elos.length) return "N/A";

  return elos[0].rank;
}

function getCrossfireRankPercentageString() {
  return `Top ${((1 - crossfireRankPercentage()) * 100).toFixed(2)}%`;
}

function prefireRankPercentage() {
  const elos = user.value?.elo_ranks_percentage.filter(
    (elo) => elo.mod.toLowerCase() == "prefire"
  );
  if (!elos.length) return "N/A";

  return elos[0].rank;
}

function getPrefireRankPercentageString() {
  return `Top ${((1 - prefireRankPercentage()) * 100).toFixed(2)}%`;
}

function prefireElo() {
  const elos = user.value?.elos.filter((elo) => elo.mod.toLowerCase() == "prefire");
  if (!elos.length) return 0;

  return elos[0].elo;
}

function crossfireRank() {
  const elos = user.value?.elo_ranks.filter(
    (elo) => elo.mod.toLowerCase() == "crossfire"
  );
  if (!elos.length) return "N/A";

  return elos[0].rank;
}
function prefireRank() {
  const elos = user.value?.elo_ranks.filter((elo) => elo.mod.toLowerCase() == "prefire");
  if (!elos.length) return "N/A";

  return elos[0].rank;
}

// needed in order to handle when a user refreshes the page and the active team is not set
watch(
  () => store.getters.activeTeam,
  (currentValue) => {
    activeTeam.value = currentValue;
    getSubscribedGrenadeGroups();
    getSubscribedSets();
  }
);

watch(
  () => route.path,
  () => {
    getUser();
  }
);

watch(
  () => bodyLoading.value,
  () => {
    if (!bodyLoading.value) loadHashValue();
  }
);

const displayedPages = computed(() => {
  if (totalPageCount.value <= maxVisiblePages.value) {
    return Array.from({ length: totalPageCount.value }, (_, i) => i + 1);
  }

  let start = Math.max(1, currentPage.value - Math.floor(maxVisiblePages.value / 2));
  let end = Math.min(start + maxVisiblePages.value - 1, totalPageCount.value);

  if (end - start + 1 < maxVisiblePages.value) {
    start = Math.max(1, end - maxVisiblePages.value + 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});

const hasEllipsis = computed(() => {
  return totalPageCount.value > maxVisiblePages.value && 
         !displayedPages.value.includes(totalPageCount.value);
});

const showLastPage = computed(() => {
  return totalPageCount.value > maxVisiblePages.value;
});

function changePage(page) {
  currentPage.value = page;
  getPlayerMatchHistory();
}

getUser();
</script>

<style>
.filter-dark {
  filter: invert(70%);
}
.filter-invert {
  filter: invert(100%);
}
.refrag-img-bg {
  background-color: rgb(75 85 99);
  background-size: cover;
}
.grow-transition {
  transition: width 0.3s;
}
.first-background {
  background-image: url("../assets/horizontal-topography.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.topography-overlay {
  background: linear-gradient(to right, #0F141A50, #0F141A);
}
</style>

