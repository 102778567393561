<template>
  <div class="rounded-lg bg-gray-900 flex flex-col gap-4 overflow-hidden p-4">
    <div class="flex gap-2 items-center">
      <div class="flex flex-col gap-1 flex-1">
        <h2 class="text-text-secondary text-sm">{{ toTitleCase(props.stat.friendly_title || props.stat.title) }}</h2>
        <h1 class="text-text-primary text-lg font-medium">{{ formatStatString(props.stat.value) }}</h1>
      </div>
      <div class="flex gap-1">
        <h1 class="font-medium flex" :class="[props.style === 'strength' ? 'text-success-400' : 'text-danger-400']">
          {{ formatDifferenceString(props.stat.difference) }}
        </h1>
        <!-- <img src="../assets/icons/downarrow.svg" class="h-3 w-3" alt="Arrow down icon" /> -->
      </div>
    </div>

    <div class="w-full">
      <div class="w-full h-px bg-gray-800"/>
    </div>

    <div v-if="style === 'strength'" class="text-sm text-text-secondary flex-1">
      {{ props.playerName ? `${props.playerName}'s` : 'Your' }} {{ toTitleCase(props.stat.friendly_title || props.stat.title) }} in this match was <span class="text-success-400">{{ Math.abs(props.stat.percentDifference) }}% better</span> than {{ props.playerName ? 'their' : 'your' }} rank's average.
    </div>
    <div v-else class="text-sm text-text-secondary flex-1">
      {{ props.playerName ? `${props.playerName}'s` : 'Your' }} {{ toTitleCase(props.stat.friendly_title || props.stat.title) }} in this match was <span class="text-danger-400">{{ Math.abs(props.stat.percentDifference) }}% worse</span> than {{ props.playerName ? 'their' : 'your' }} rank's average.
    </div>

    <div v-if="showImproveButton">
      <p class="text-xs text-text-secondary text-center pb-2">This will launch a {{ props.stat.recommended_mods[0].modifier.friendly_name || 'Refrag' }} training server.</p>
      <button @click="openServerModal(props.stat)" class="rounded-md py-2.5 w-full bg-primary-600 text-text-primary text-xs z-50 cursor-pointer hover:bg-primary-500 duration-300">
        Improve Now
      </button>
    </div>

  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  stat: {
    type: Object
  },
  style: {
    type: String
  },
  showImproveButton: {
    type: Boolean,
    default: true
  },
  playerName: {
    type: String
  }
})

const emit = defineEmits([
  "open-server-modal",
]);

function openServerModal() {
  emit("open-server-modal", props.stat);
}

function formatStatString(val) {
  let formattedStat;

  const value = parseFloat(val);

  switch (props.stat.unit_type) {
    case 'integer':
      formattedStat = Number.isInteger(value) ? value.toString() : value.toFixed(2).replace(/\.00$/, '');
      break;
    case 'percentage':
      formattedStat = (value * 100).toFixed(0) + '%';
      break;
    case 'decimal':
      formattedStat = value.toFixed(2);
      break;
    case 'degrees':
      formattedStat = value.toFixed(1) + '°';
      break;
    case 'seconds':
      formattedStat = value.round(stat) + 's';
      break;
    case 'nanoseconds':
      formattedStat = (value / 1000000000).toFixed(3) + 's';
      break;
    default:
      ""
      break;
  }
  return formattedStat;
}

function formatDifferenceString(val) {
  let formattedDifference = formatStatString(val);

  if (val > 0) {
    formattedDifference = '+' + formattedDifference;
  }

  return formattedDifference;
}

function toTitleCase(str) {
    return str
        .split('_') // Split the string by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
}

</script>
