<template>
  <div class="w-full flex flex-col gap-9">
    <div class="flex flex-col gap-6 w-full">
      <div class="flex flex-col lg:flex-row items-start w-full">
        <div class="flex items-center gap-2 flex-1">
          <h1 class="text-text-primary text-3xl font-semibold">Statistics</h1>
          <div class="has-tooltip">
            <img src="../../assets/icons/info-icon.svg" alt="Avatar" class="w-6 h-6 cursor-pointer" />
            <div class="tooltip bg-gray-900 rounded-lg p-2 ml-8 -mt-7">
              <p class="text-text-secondary text-xs">Comparison of {{ playerName(props.selectedUser) }}'s match performance to their {{ props.matchData?.platform == 'faceit' ? 'Faceit' : props.matchData?.match_type }} ranks average</p>
            </div>
          </div>
        </div>

        <!-- dropdown menu -->
        <Menu as="div" class="pt-2 relative inline-block">
          <div>
            <MenuButton
              class="group rounded-md bg-gray-800 px-3.5 py-2 w-full lg:w-[240px] text-center text-sm font-medium text-white hover:outline hover:outline-gray-500 focus:outline-outline focus:outline-gray-500"
            >
              <span class="flex w-full items-center justify-between">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <img class="w-8 h-8 bg-red-500 rounded-full" :src="imageURL()" />
                  <span class="flex min-w-0 flex-1 flex-col">
                    {{ playerName(props.selectedUser) }}
                  </span>
                </span>
                <ChevronDownIcon
                  class="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full lg:w-[250px] origin-top divide-y divide-gray-200 rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-for="(user, index) in users" :key="user">
                  <div>
                    <a
                      @click="updateSelectedUser(user)"
                      :class="[
                        'flex gap-2 px-4 py-2 text-sm text-text-primary cursor-pointer hover:bg-gray-800 duration-300 items-center',
                      ]"
                    >
                      <img
                        class="w-8 h-8 bg-red-500 rounded-full"
                        :src="user.player.steam_profile?.avatar"
                      />
                      <h1 class="flex-1">{{ playerName(user) }}</h1>
                      <CheckIcon
                        v-if="
                          props.selectedUser?.player?.steam_id === user?.player?.steam_id ||
                          props.selectedUser?.steam_id == user?.player?.steam_id
                        "
                        class="h-5 w-5 text-primary-500"
                      />
                    </a>
                    <div
                      v-if="
                        index < users.length - 1 &&
                        users[index + 1]?.match_performance?.last_round_team !=
                        user.match_performance?.last_round_team
                      "
                      class="border-b border-gray-800 mt-1 mb-1"
                    />
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div class="flex flex-col gap-16 w-full">
        <div class="flex flex-col gap-4">
          <div class="flex gap-2 items-center">
            <h1 class="text-text-primary text-2xl font-semibold flex">Weaknesses</h1>
            <span
              class="w-6 h-6 flex items-center justify-center text-danger-400 bg-danger-900 rounded-md"
              >{{ Math.min(negativeStats.length, 4) }}</span
            >
          </div>

          <div
            v-if="orderedStats && negativeStats.length > 0"
            class="grid grid-cols-12 gap-6 items-center items-stretch"
          >
            <div class="flex flex-col gap-3 flex-1 col-span-12 lg:col-span-4">
              <div
                v-for="stat in negativeStats.slice(0, 4)"
                class="flex p-4 rounded-lg bg-gray-900 items-center cursor-pointer hover:bg-gray-800 duration-300"
                :class="[
                  props.selectedStat?.title === stat.title
                    ? 'border border-primary-500'
                    : ' border border-gray-900',
                ]"
                @click="updateSelectedStat(stat)"
              >
                <h2 class="text-text-secondary flex-1">{{ toTitleCase(stat?.friendly_title) }}</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ formatStatString(stat.value, stat.unit_type) }}
                </h1>
              </div>
            </div>

            <div v-if="props.selectedStat" class="flex flex-col lg:flex-row col-span-12 lg:col-span-8 rounded-lg bg-gray-900">
              <div class="flex flex-1 flex-col gap-4">
                <div class="flex gap-4 pt-6 px-6 items-center">
                  <div class="flex flex-col gap-1 flex-1">
                    <h2 class="text-text-secondary text-sm">
                      {{ toTitleCase(props.selectedStat?.friendly_title) }}
                    </h2>
                    <h1 class="text-text-primary text-lg font-medium">
                      {{
                        formatStatString(
                          props.selectedStat.value,
                          props.selectedStat.unit_type
                        )
                      }}
                    </h1>
                  </div>
                  <div class="flex gap-1">
                    <h1 class="font-medium text-danger-400 flex">
                      <span v-if="!props.selectedStat.is_ascending > 0">+</span>
                      <span v-else>-</span>
                      <h1>
                        {{
                          formatStatString(
                            props.selectedStat.difference,
                            props.selectedStat.unit_type
                          )
                        }}
                      </h1>
                    </h1>
                  </div>
                </div>

                <div class="w-full px-6">
                  <div class="w-full h-px bg-gray-800" />
                </div>

                <div class="text-sm text-text-secondary px-6 pb-2 flex-1">
                  {{ playerName(props.selectedUser) }}'s
                  {{ toTitleCase(props.selectedStat.friendly_title || props.selectedStat.title) }} in this match was
                  <span class="text-danger-400"
                    >{{
                      Math.abs(
                        parseFloat(
                          parseFloat(props.selectedStat.percentDifference).toFixed(2)
                        )
                      )
                    }}% worse</span
                  >
                  than their rank's average.
                </div>

                <button
                  v-if="selectedUser?.player?.steam_id === user?.steam_id"
                  @click="updateServerModal()"
                  class="py-2.5 mx-6 mb-6 rounded-md bg-primary-600 text-text-primary text-xs z-50 cursor-pointer hover:bg-primary-500 duration-300"
                >
                  Improve Now
                </button>
              </div>

              <div class="flex-1 flex items-stretch p-6">
                <div
                  class="flex-1 bg-gray-800 rounded-lg border-gray-700 border p-5 flex flex-col"
                >
                  <div class="flex-1 flex gap-8">
                    <div class="flex flex-col gap-1">
                      <div class="flex gap-1 items-center">
                        <div class="h-2 w-2 rounded-sm bg-primary-500" />
                        <h2 class="text-text-secondary text-xs">
                          {{ playerName(props.selectedUser) }}'s Avg.
                        </h2>
                      </div>
                      <h1 class="text-text-primary text-lg font-medium">
                        {{
                          formatStatString(
                            props.userData?.stats[selectedStat.title]?.players_value,
                            selectedStat.unit_type
                          )
                        }}
                      </h1>
                    </div>
                    <div class="flex flex-col gap-1">
                      <div class="flex gap-1 items-center">
                        <div class="h-2 w-2 rounded-sm bg-secondary-500" />
                        <h2 class="text-text-secondary text-xs">Target Avg.</h2>
                      </div>
                      <h1 class="text-text-primary text-lg font-medium">
                        {{
                          formatStatString(
                            props.userData?.stats[selectedStat.title]
                              ?.rating_range_average_value,
                            selectedStat.unit_type
                          )
                        }}
                      </h1>
                    </div>
                  </div>
                  <LineChart
                    v-if="props.userMatchHistory"
                    :options="options"
                    :chartData="getChartData()"
                    class="w-full max-h-[112px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="orderedStats && negativeStats.length === 0"
            class="flex col-span-8 rounded-lg bg-gray-900"
          >
            <div class="flex flex-col gap-4 p-6">
              <h1 class="text-text-primary text-lg font-medium">No weaknesses found!</h1>
              <p class="text-text-secondary text-sm">
                {{ playerName(props.selectedUser) }} performed well in this match!
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex gap-2 items-center">
            <h1 class="text-text-primary text-2xl font-semibold flex">Strengths</h1>
            <span
              class="w-6 h-6 flex items-center justify-center text-success-400 bg-success-900 rounded-md"
              >{{ Math.min(positiveStats.length, 3) }}</span
            >
          </div>

          <div v-if="positiveStats?.length > 0" class="grid grid-cols-1 lg:grid-cols-3 w-full gap-6">
            <StatBlock
              v-for="stat in positiveStats.slice(0, 3)"
              :key="stat.title"
              :stat="stat"
              :style="'strength'"
              :show-improve-button="false"
              :player-name="playerName(props.selectedUser)"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex gap-2 items-center">
            <h1 class="text-text-primary text-2xl font-semibold flex">All Statistics</h1>
          </div>

          <div class="grid lg:grid-cols-2 w-full gap-6">
            <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg">
              <div class="px-4 py-3 text-text-primary">General</div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Kills</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.kills }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Assists</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.assists }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Deaths</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.deaths }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">K/D Ratio</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    calculateKD(
                      userMatchData?.match_performance?.kills,
                      userMatchData?.match_performance?.deaths
                    )
                  }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">HS Kill %</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    (userMatchData?.match_performance?.headshot_kill_percentage * 100).toFixed(0)
                  }}%
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">ADR</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    userMatchData?.match_performance?.average_damage_per_round.toFixed(0)
                  }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">HLTV Rating</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.hltv_rating.toFixed(2) }}
                </h1>
              </div>
            </div>

            <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg">
              <div class="px-4 py-3 text-text-primary">Aim</div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Time To Kill</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    convertNanoSeconds(userMatchData?.match_performance?.time_to_kill)
                  }}s
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Time To Damage</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    convertNanoSeconds(userMatchData?.match_performance?.time_to_damage)
                  }}s
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Spotted Accuracy</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    (userMatchData?.match_performance?.spotted_accuracy * 100)?.toFixed(
                      0
                    )
                  }}%
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Headshot %</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    (
                      userMatchData?.match_performance?.headshot_percentage * 100
                    )?.toFixed(0)
                  }}%
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Counter Strafe %</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    (
                      userMatchData?.match_performance?.shots_at_low_velocity_rate * 100
                    )?.toFixed(0)
                  }}%
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Crosshair Placement</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.crosshair_placement?.toFixed(2) }}°
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Recoil Control Accuracy</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{(userMatchData?.match_performance?.recoil_control_accuracy * 100).toFixed(0)}}%
                </h1>
              </div>
            </div>

            <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg">
              <div class="px-4 py-3 text-text-primary">Entry & Trades</div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Opening Kill Successes</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.opening_success }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Opening Kill Fails</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.opening_fails }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Trade Kill Successes</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.trade_kill_successes }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Trade Kill Fails</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.trade_kill_fails }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Trade Death Successes</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.traded_death_successes }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Trade Death Fails</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.traded_death_fails }}
                </h1>
              </div>
            </div>

            <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg">
              <div class="px-4 py-3 text-text-primary">Utility</div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Flash Assists</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.flash_assists }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Enemies Flashed</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.enemies_flashed }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Friends Flashed</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.friends_flashed }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Average Flash Time</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.average_flash_time.toFixed(2) }}s
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Utility Damage</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  {{ userMatchData?.match_performance?.utility_damage }}
                </h1>
              </div>
              <div class="p-4 flex gap-2 items-center">
                <h2 class="text-text-secondary flex-1">Unused Utility Value</h2>
                <h1 class="text-text-primary text-lg font-medium">
                  ${{ userMatchData?.match_performance?.unused_utility_value }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive, defineEmits, watch } from "vue";
import StatBlock from "./StatBlock.vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/solid";
import unknownUserImage from "../../assets/unknown-user.png";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { createClickablePointsPlugin } from "../../utils/chartPlugins.js";
// Create the plugin
const clickablePointsPlugin = createClickablePointsPlugin(
  (index) => props.userMatchHistory[props.userMatchHistory.length - 1 - index]?.match_id,
  (matchId) => router.push(`/matches/${matchId}`)
);

// Register the plugin
Chart.register(...registerables, clickablePointsPlugin);

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const user = ref(auth.user());
const mappedStats = ref([]);
const statAverage = ref(0);

const props = defineProps({
  matchData: {
    type: Object,
    required: true,
  },
  userData: Object,
  selectedUser: Object,
  selectedStat: Object,
  userMatchHistory: Object,
});

const emit = defineEmits([
  "update-user-data",
  "update-selected-user",
  "update-selected-stat",
  "get-match-data",
  "openServerModal",
]);

const users = computed(() => {
  return props.matchData?.players.sort((a, b) => {
    return a.match_performance?.last_round_team - b.match_performance?.last_round_team;
  });
});

const orderedStats = computed(() => {
  // Ensure userData and userMatchData are available
  if (!props.userData || !userMatchData.value) {
    return [];
  }

  // Extract user data and match performance
  const userStats = props.userData.stats;
  const matchStats = userMatchData.value.match_performance;

  let differences = [];

  // Iterate over the keys in userStats
  for (const key in userStats) {
    // Skip the key if it doesn't exist in matchStats
    if (!matchStats[key]) {
      continue;
    }

    if (!userStats[key].recommended_mods?.length) {
      continue;
    }

    // Calculate the difference between the user's average and the match performance
    let difference;
    let percentDifference;

    // Adjust the difference calculation based on the is_ascending flag
    if (userStats[key].is_ascending) {
      // For ascending stats, a negative difference means the match performance is worse
      difference = matchStats[key] - userStats[key].rating_range_average_value;
    } else {
      // For descending stats, a positive difference means the match performance is worse
      difference = userStats[key].rating_range_average_value - matchStats[key];
    }

    // Calculate the percentage difference
    let playerValue =
      userStats[key].rating_range_average_value === 0
        ? 1
        : userStats[key].rating_range_average_value;
    percentDifference = (difference / playerValue) * 100;

    // Temporarily set the counter strafing stat name
    let friendly_title = key;
    if(key == "shots_at_low_velocity_rate") {
      friendly_title = "counter_strafing";
    }

    // Add the difference to the differences array
    differences.push({
      title: key,
      friendly_title: friendly_title,
      value: matchStats[key],
      difference: difference.toFixed(2),
      percentDifference: percentDifference.toFixed(2),
      unit_type: userStats[key].unit_type,
      is_ascending: userStats[key].is_ascending,
      recommended_mods: userStats[key].recommended_mods,
    });
  }

  // Sort the differences to get the stats with the most significant percentage negative impact first
  differences.sort((a, b) => a.percentDifference - b.percentDifference);
  // Return the two stats with the largest negative percentage differences
  if(differences[0]?.percentDifference < 0) {
    updateSelectedStat(differences[0]);
  }
  return differences;
});

const negativeStats = computed(() => {
  return orderedStats.value.filter((stat) => stat.percentDifference < 0);
});

const positiveStats = computed(() => {
  return orderedStats.value
    .filter((stat) => stat.percentDifference > 0)
    ?.sort((a, b) => b.percentDifference - a.percentDifference);
});

const playerName = (player) => {
  return (
    player?.player?.steam_profile?.personaName ||
    player?.steam_profile?.personaName ||
    player?.player?.name ||
    player?.name
  );
};

function convertNanoSeconds(nanoSeconds) {
  return (nanoSeconds / 1000000000).toFixed(3);
}

function calculateKD(kills, deaths) {
  return (kills / deaths).toFixed(2) || 0;
}

function imageURL() {
  if (props.selectedUser?.steam_profile) {
    return props.selectedUser.steam_profile.avatar;
  } else if (props.selectedUser?.player.steam_profile) {
    return props.selectedUser.player.steam_profile.avatar;
  }
  return unknownUserImage;
}

function updateServerModal() {
  emit("openServerModal", props.selectedStat);
}

function updateSelectedUser(user) {
  emit("update-selected-user", user);
}

function updateSelectedStat(stat) {
  updateUserStatHistory(stat);
  emit("update-selected-stat", stat);
}

function getPlayerData() {
  var steamId;
  if (props.selectedUser?.steam_profile) {
    steamId = props.selectedUser?.steam_id;
  } else {
    steamId = props.selectedUser?.player?.steam_id;
  }

  axios
    .get(`/matches/user_profile`, {
      params: {
        steam_id: steamId,
        platform: props.matchData.platform,
        match_type: props.matchData.match_type,
      },
    })
    .then((response) => {
      emit("update-user-data", response.data);
    })
    .catch((error) => {});
}

function getPlayerPastStats() {
  var steamId;
  if (props.selectedUser?.steam_profile) {
    steamId = props.selectedUser?.steam_id;
  } else {
    steamId = props.selectedUser?.player?.steam_id;
  }

  axios
    .get(`/matches/recent_match_stats`, {
      params: {
        'steam_id': steamId,
        'match_type': props.matchData.match_type,
        'platform': props.matchData.platform,
      },
    })
    .then((response) => {
      emit("update-user-match-history", response.data.matches);
    })
    .catch((error) => {});
}

function updateUserStatHistory(stat) {
  let mappedStatHistory = props.userMatchHistory?.map((match) => {
    return formatData(match[stat.title], stat.unit_type);
  });

  statAverage.value = formatData(
    props.userData.stats[stat.title].rating_range_average_value,
    stat.unit_type
  );
  mappedStats.value = mappedStatHistory;
}

function toTitleCase(str) {
  return str
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words with a space
}

const userMatchData = computed(() => {
  // Ensure matchData is available and has players
  if (!props.matchData || !props.matchData.players || !props.selectedUser) {
    return null;
  }

  var steamId;
  if (props.selectedUser?.steam_profile) {
    steamId = props.selectedUser?.steam_id;
  } else {
    steamId = props.selectedUser?.player?.steam_id;
  }

  // Find and return the specific player's data based on steam_id
  return (
    props.matchData.players.find((player) => player.player?.steam_id == steamId) || null
  );
});

function formatData(val, type) {
  let formattedStat;
  let value = Math.abs(parseFloat(val));

  switch (type) {
    case "integer":
      formattedStat = Number.isInteger(value)
        ? value.toString()
        : value.toFixed(2).replace(/\.00$/, "");
      break;
    case "percentage":
      formattedStat = (value * 100).toFixed(0);
      break;
    case "decimal":
      formattedStat = value.toFixed(2);
      break;
    case "degrees":
      formattedStat = value.toFixed(1);
      break;
    case "seconds":
      formattedStat = value.round(stat);
      break;
    case "nanoseconds":
      formattedStat = (value / 1000000000).toFixed(2);
      break;
    default:
      value;
      break;
  }

  return formattedStat;
}

function formatStatString(val, type) {
  let formattedStat;
  let value = Math.abs(parseFloat(val));

  switch (type) {
    case "integer":
      formattedStat = parseFloat(value.toFixed(2));
      break;
    case "percentage":
      formattedStat = (value * 100).toFixed(0) + "%";
      break;
    case "decimal":
      formattedStat = value.toFixed(2);
      break;
    case "degrees":
      formattedStat = value.toFixed(1) + "°";
      break;
    case "seconds":
      formattedStat = value.round(stat) + "s";
      break;
    case "nanoseconds":
      formattedStat = (value / 1000000000).toFixed(2);
      +"s";
      break;
    default:
      "";
      break;
  }

  return formattedStat;
}

const labels = computed(() => {
  return props.userMatchHistory?.map((match) => {
    return match.match_id;
  });
});

// Update the options object
const options = computed(() => ({
  spanGaps: true,
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    title: {
      display: false, // Hide the title
    },
    clickablePoints: clickablePointsPlugin,
  },
  elements: {
    point: {
      radius: 4,
      hoverRadius: 6,
    },
  },
  scales: {
    x: {
      display: false, // Hide the X-axis labels and grid lines
      grid: {
        color: "transparent",
        borderColor: "transparent",
      },
    },
    y: {
      grid: {
        borderDash: [5, 5],
        color: "#37485D",
        borderColor: "transparent",
      },
      ticks: {
        color: "#9CADC4",
        maxTicksLimit: 5,
      },
      beginAtZero: true, // Start the scale at 0
      suggestedMin: 0, // Ensure the minimum value is always 0 or lower
    },
  },
  maintainAspectRatio: false, // Allow the chart to adjust its size
  responsive: true,
}));

// Update the getChartData function
function getChartData() {
  if (props.userMatchHistory?.length === 0 || !props.selectedStat) {
    return null;
  }

  // Create reversed arrays for labels and data
  const reversedLabels = [...props.userMatchHistory]
    .reverse()
    .map((match) => match.date.split("T")[0]);
  const reversedData = [...props.userMatchHistory]
    .reverse()
    .map((match) =>
      formatData(match[props.selectedStat.title], props.selectedStat.unit_type)
    );

  // Calculate the maximum value from both datasets
  const maxValue = Math.max(...reversedData, statAverage.value);

  // Calculate a buffer (20% higher than the max value)
  const buffer = maxValue * 0.2;

  return {
    labels: reversedLabels,
    datasets: [
      {
        data: reversedData,
        fill: true,
        backgroundColor: "#6B70D115",
        borderColor: "#6B70D1",
        tension: 0,
        pointRadius: 3,
        pointHoverBackgroundColor: "#6B70D1",
        pointHoverBorderColor: "#FFFFFF",
      },
      {
        data: Array(props.userMatchHistory.length).fill(statAverage.value),
        fill: false,
        borderColor: "#C44CB6",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  };
}

watch(
  () => props.selectedUser,
  (newVal, oldVal) => {
    if (newVal === oldVal) {
      return;
    }
    getPlayerData();
    getPlayerPastStats();
  }
);

watch(
  () => route.params.id,
  (newId, oldId) => {
    if (newId !== oldId) {
      getPlayerData();
      getPlayerPastStats();
      emit("get-match-data");
    }
  }
);

if (!props.userData && props.selectedUser) {
  getPlayerData();
}

if (!props.userMatchHistory && props.selectedUser) {
  getPlayerPastStats();
}
</script>
