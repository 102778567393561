<template>
  <div class="w-full h-full bg-gray-900 flex relative">
    <!-- sidebar -->
    <Sidebar :currentStep="currentStep" />

    <!-- main content -->
    <div
      v-if="user"
      class="w-full h-full flex lg:justify-center lg:items-center z-10 mt-[58px] lg:mt-0 lg:ml-[320px]"
    >
      <div
        class="w-full h-full flex lg:justify-center relative px-4 py-8 lg:py-0 lg:px-0"
      >
        <WelcomePage
          @updateOnboardingStep="updateOnboardingStep"
          :currentStep="currentStep"
          v-if="currentStep === 0"
        />
        <ConnectAccountsPage
          @updateOnboardingStep="updateOnboardingStep"
          :currentStep="currentStep"
          v-else-if="currentStep === 1"
        />
        <SteamBotPage
          @updateOnboardingStep="updateOnboardingStep"
          :currentStep="currentStep"
          v-else-if="currentStep === 2"
        />
        <MatchHistoryPage
          @updateOnboardingStep="updateOnboardingStep"
          :currentStep="currentStep"
          v-else-if="currentStep === 3"
        />
        <SubscribePage
          @updateOnboardingStep="updateOnboardingStep"
          :currentStep="currentStep"
          v-else-if="currentStep === 4"
        />
        <PlayNowPage
          @updateOnboardingStep="updateOnboardingStep"
          @finishOnboarding="finishOnboarding"
          :currentStep="currentStep"
          v-else-if="currentStep === 5"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Sidebar from "../components/Onboarding/Sidebar.vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { ref, onMounted } from "vue";
import WelcomePage from "../components/Onboarding/WelcomePage.vue";
import SubscribePage from "../components/Onboarding/SubscribePage.vue";
import ConnectAccountsPage from "../components/Onboarding/ConnectAccountsPage.vue";
import PlayNowPage from "../components/Onboarding/PlayNowPage.vue";
import SteamBotPage from "../components/Onboarding/SteamBotPage.vue";
import axios from "axios";
import { useRouter } from "vue-router";
import MatchHistoryPage from "../components/Onboarding/MatchHistoryPage.vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useHead } from '@unhead/vue'

const auth = useAuth();
const user = ref(auth.user());
const currentStep = ref(user.value.onboarded_steps_completed);
const router = useRouter();
const gtm = useGtm();

onMounted(() => {
  if (!gtm) return;

  gtm.push({ event: "tutorial_begin" });
});
useHead({
    title: 'Refrag | Welcome!',
})

function logout() {
    auth.logout();
}

function updateOnboardingStep(data) {
  currentStep.value = data.value;
  axios({
    url: "/users/update_user",
    method: "PUT",
    data: {
      onboarded_steps_completed: data.value,
    },
  }).catch(() => {
    currentStep.value = user.value.onboarded_steps_completed;
  });
}

function finishOnboarding() {
  axios({
    url: "/users/update_user",
    method: "PUT",
    data: {
      onboarded_steps_completed: 5,
    },
  }).then(() => {
    gtm.push({ event: "tutorial_complete" });
    currentStep.value = 5;
    auth.fetch();
    router.push({ name: "Dashboard" });
  });
}
</script>
