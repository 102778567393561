//./plugins/posthog.js
import posthog from "posthog-js";

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            import.meta.env.VITE_POSTHOG_API_KEY,
            {
                api_host: import.meta.env.VITE_POSTHOG_API_HOST,
                person_profiles: 'identified_only',
                loaded: (posthog) => {
                    const enabledCookies = app.config.globalProperties.$cookies.get('ncc_e')?.split('~') || [];
                    if (!enabledCookies.includes('ncc_analytics')) {
                        posthog.opt_out_capturing()
                    } else {
                        posthog.opt_in_capturing()
                    }
                    if (import.meta.env.MODE === 'development') posthog.debug();
                }
            }
        );
    },
};
