import { createApp, getCurrentInstance } from 'vue'
import App from './App.vue'
import router from "./router/index"
import config from './config';
import axios from 'axios';
import { store } from './store';
import { createAuth } from '@websanova/vue-auth';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverDevise from '@websanova/vue-auth/dist/drivers/auth/devise.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import VueClipboard from 'vue3-clipboard'
import FlagIcon from 'vue-flag-icon'
import { createGtm } from '@gtm-support/vue-gtm';
import { createHead, VueHeadMixin } from '@unhead/vue'
import { InferSeoMetaPlugin } from '@unhead/addons'
import { CapoPlugin } from 'unhead'
import VueActionCable from 'vue-action-cable'
import VueKonva from 'vue-konva';
import posthogPlugin from "./plugins/posthog"; //import the plugin.
import './index.css'

import Appsignal from "@appsignal/javascript";
import { errorHandler } from "@appsignal/vue";
import { plugin } from "@appsignal/plugin-window-events";
import VueCookies from 'vue-cookies';

const actionCableVueOptions = {
  connectionUrl: config.ws,
  connectImmediately: true,
};

axios.defaults.baseURL = config.uri;

axios.defaults.headers.common['X-GAME'] = store.getters.selectedGame

const auth = createAuth({
  plugins: {
    router: router,
    http: axios
  },
  drivers: {
    auth: driverDevise,
    router: driverRouterVueRouter,
    http: driverHttpAxios
  },
  options: {
    rolesKey: 'type',
    notFoundRedirect: { name: 'Dashboard' },
    loginData: {
      url: 'auth/sign_in',
      method: 'POST',
      redirect: '/',
    },
    registerData: {
      url: 'auth.json',
      method: 'POST',
      redirect: '/',
    },
    logoutData: {
      url: 'auth/sign_out.json',
      method: 'DELETE',
      redirect: '/login',
    },
    refreshData: {
      enabled: false,
    },
    fetchData: {
      url: 'me',
    },
  }
});

const appsignal = new Appsignal({
  // trunk-ignore(gitleaks/generic-api-key)
  key: import.meta.env.VITE_APPSIGNAL_KEY,
  revision: import.meta.env.VITE_REVISION || 'unknown',
});

appsignal.use(plugin({
  onerror: true,
  onunhandledrejection: true,
}))

const head = createHead({
  plugins: [
    InferSeoMetaPlugin(),
    CapoPlugin(),
  ]
})
const app = createApp(App)
  .use(head)
  .use(auth)
  .use(router)
  .use(store)
  .use(FlagIcon)
  .use(VueKonva)
  .use(VueCookies)
  .use(VueClipboard, { autoSetContainer: true, appendToBody: true })
  .use(VueActionCable, actionCableVueOptions)
  .use(posthogPlugin)
  .use(
    createGtm({
      id: 'GTM-N8ZNXLCT', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      enabled: false,
    }),
  );

app.mixin(VueHeadMixin);
app.config.errorHandler = errorHandler(appsignal, app);
app.mount('#app')
