<template>
  <TransitionRoot as="template" :show="showUnsubscribeModal">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto w-full h-full flex justify-center items-center">
      <div class="relative w-full h-full bg-gray-500 opacity-75" @click="showUnsubscribeModal = !showUnsubscribeModal">

      </div>

      <div class="absolute flex flex-col justify-start rounded-md">

        <div class="refrag-img-bg py-12 px-56 w-full relative rounded-t-lg" v-bind:style="{ backgroundImage: 'url(' + refragBackground + ')' }">
          <h3 class="text-xl leading-6 font-medium text-white absolute left-0 top-0 p-6">{{ getRole() }}</h3>
          <XIcon class="h-4 w-4 text-white absolute right-2 top-2 cursor-pointer" @click="showUnsubscribeModal = !showUnsubscribeModal" aria-hidden="true" />
        </div>

        <div class="py-4 bg-gray-950 rounded-x-xl rounded-b-xl">
          <h1 class="text-2xl leading-6 font-medium text-white px-6">Are you sure you want to cancel?</h1>
          <h2 class="text-sm leading-6 font-medium text-gray-300 px-6">If you cancel, <span class="text-white">you will lose your Refrag access <span v-if="userData.subscription_end_date">on {{ moment(userData.subscription_end_date).format('MMM Do, YYYY') }}</span></span></h2>

          <div class="mx-6 mt-4 bg-gray-900 border border-gray-700 rounded-md flex flex-row items-center">
            <p class="px-6 py-6 text-gray-300 text-sm">Lose access to all Refrag features</p>
            <ul class="text-gray-300 list-disc text-[8px] px-4 py-2">
              <li>20+ aim training modes</li>
              <li>Bootcamp</li>
              <li>Routines</li>
            </ul>
          </div>

          <div class="mx-6 mt-4 bg-gray-900 border border-gray-700 rounded-md flex flex-row items-center">
            <p class="px-6 py-6 text-gray-300 text-sm">Lose access to any of your saved data</p>
            <ul class="text-gray-300 list-disc text-[8px] px-4 py-2">
              <li>Demos</li>
              <li>NADR content</li>
              <li>Statistics</li>
              <li>Leaderboard entries</li>
            </ul>
          </div>

          <div class="mt-4 flex justify-end items-center px-6">
            <h3 @click="showUnsubscribeModal = !showUnsubscribeModal" class="px-4 text-gray-300 text-xs cursor-pointer">Nevermind, keep <span class="text-white font-bold">Refrag Access</span></h3>
            <button class="bg-red-600 hover:bg-red-500 text-white text-xs py-2 px-3 rounded shadow"
              @click="cancel_xsolla_subscription()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>



    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/solid";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { ref } from "vue";
import axios from "axios";
import refragBackground from "../assets/refrag-logo-background.png";
import { useGtm } from "@gtm-support/vue-gtm";
import moment from "moment";

const props = defineProps({
  showUnsubscribeModal: {
    type: Boolean
  },
  subscription_id: {
    type: Number
  }
})

const auth = useAuth();
const user = ref(auth.user());
const gtm = useGtm();
const userData = ref();

function getUserInfo() {
  axios({
    method: "get",
    url: "/users/my_settings",
  }).then((data) => {
    userData.value = data.data;
  });
}

function cancel_xsolla_subscription() {
  gtm.push({
    event: "cancel_subscription",
  });

  axios({
    method: "post",
    url: `/subscriptions/${props.subscription_id}/cancel`,
    data: {},
  }).then(() => {
    auth.fetch();
    window.location = "https://hqrjsk70yrj.typeform.com/to/WjpvXaJW#email=" + user.value.email
  });
}

function getRole() {
  switch (user.value?.access_level) {
    case 5:
      return "Admin";
    case 4:
      return "Pro Tier";
    case 3:
      return "Team Tier";
    case 2:
      return "Competitor Tier";
    case 1:
      return "Player Tier";
    default:
      return "Free User";
  }
}

getUserInfo();

</script>
