<template>
  <div v-if="props.matchData?.highlights?.length > 0" class="flex flex-col gap-4 w-full">
    <div class="flex items-center gap-3">
      <h1 class="text-text-primary text-3xl font-semibold flex-1">Highlights</h1>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div v-for="highlight in filteredHighlights" :key="highlight.status" class="flex flex-col">
        <div class="aspect-[16/9] w-full bg-gray-800 rounded-t-lg overflow-hidden">
          <iframe
            v-if="highlight?.status == 'processed' || highlight?.status == 'submitted' || highlight?.status == 'created' || highlight?.status == 'pruned'"
            :key="highlight?.status"
            class="w-full h-full"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            :src="highlight.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
          >
          </iframe>
        </div>
        <div class="flex flex-col gap-2 bg-gray-900 rounded-b-lg p-4">
          <h1 class="text-text-primary text-lg font-medium">{{ highlight.title }}</h1>
          <h2 class="text-text-secondary text-sm">{{ highlight.round_number ? `Round ${highlight.round_number}` : "-" }} • {{ clipUser(highlight)?.player?.steam_profile?.personaName }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  matchData: {
    type: Object
  }
})

const filteredHighlights = computed(() => {
  return props.matchData?.highlights?.filter(
    (highlight) => highlight.status !== "created" && highlight.status !== "failed"
  );
});

const clipUser = (clip) => {
  return props.matchData?.players?.find(
    (player) => player.player.steam_id === clip.user_steam_id
  );
}

</script>
