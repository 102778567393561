<template>
    <!-- desktop -->
    <div class="hidden w-[320px] p-9 bg-gray-950 lg:flex flex-col items-start fixed inset-y-0">
        <img src="../../assets/topography.svg" alt="Refrag" class="h-full w-full absolute z-5 top-0 left-0" />
        <img class="h-6 w-auto z-10" src="../../assets/logos/refrag-horz-fill.svg" alt="Refrag" />
        <div class="pt-[72px] pb-12 gap-4 z-10">
            <h1 class="text-text-primary text-[28px] font-medium">Onboarding</h1>
            <h2 class="text-text-secondary text-sm">Setup your account in a few steps</h2>
        </div>
        <div v-if="user" class="flex-1 flex flex-col w-full z-10">
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 0 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 0">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 0 ? 'text-text-primary' : 'text-text-secondary']">
                        1
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 0 ? 'text-text-primary' : 'text-text-secondary']">
                    Welcome
                </h1>
            </div>
            <div class="w-px flex-1 ml-[18px] my-2"
                :class="[props.currentStep >= 1 ? 'bg-primary-500' : 'bg-gray-800']"
            />
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 1 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 1">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 1 ? 'text-text-primary' : 'text-text-secondary']">
                        2
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 1 ? 'text-text-primary' : 'text-text-secondary']">
                    Connect Accounts
                </h1>
            </div>
            <div class="w-px flex-1 ml-[18px] my-2"
                :class="[props.currentStep >= 2 ? 'bg-primary-500' : 'bg-gray-800']"
            />
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 2 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 2">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 2 ? 'text-text-primary' : 'text-text-secondary']">
                        3
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 2 ? 'text-text-primary' : 'text-text-secondary']">
                    Bot & Server Setup
                </h1>
            </div>
            <div class="w-px flex-1 ml-[18px] my-2"
                :class="[props.currentStep >= 3 ? 'bg-primary-500' : 'bg-gray-800']"
            />
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 3 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 3">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 3 ? 'text-text-primary' : 'text-text-secondary']">
                        4
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 3 ? 'text-text-primary' : 'text-text-secondary']">
                    Match History
                </h1>
            </div>
            <div class="w-px flex-1 ml-[18px] my-2"
                :class="[props.currentStep >= 4 ? 'bg-primary-500' : 'bg-gray-800']"
            />
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 4 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 4">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 4 ? 'text-text-primary' : 'text-text-secondary']">
                        5
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 3 ? 'text-text-primary' : 'text-text-secondary']">
                    Subscribe
                </h1>
            </div>
            <div class="w-px flex-1 ml-[18px] my-2"
                :class="[props.currentStep >= 5 ? 'bg-primary-500' : 'bg-gray-800']"
            />
            <div class="flex w-full gap-3 items-center">
                <div class="w-9 h-9 rounded-full font-medium flex items-center justify-center"
                    :class="[props.currentStep >= 5 ? 'bg-primary-500' : 'bg-none border-2 border-gray-800']">
                    <span v-if="props.currentStep > 5">
                        <CheckIcon class="h-5 w-5 text-text-primary" />
                    </span>
                    <span class="text-text-primary" v-else
                            :class="[props.currentStep == 5 ? 'text-text-primary' : 'text-text-secondary']">
                        6
                    </span>
                </div>
                <h1 class="text-text-primary font-medium"
                    :class="[props.currentStep === 5 ? 'text-text-primary' : 'text-text-secondary']">
                    Play Now
                </h1>
            </div>
        </div>
        <div v-if="user" class="flex flex-col gap-4 pt-16 z-10">
            <h3 class="text-text-secondary text-sm">{{ user?.email }}</h3>
            <p @click="logout" class="text-text-secondary text-sm cursor-pointer">Sign Out</p>
        </div>
    </div>
    <!-- mobile -->
    <div class="w-full h-[58px] fixed inset-y-0 bg-gray-950 flex lg:hidden z-20">
        <div class="w-full h-full relative">
            <img src="../../assets/topography.svg" alt="Refrag" class="h-full w-full object-cover absolute z-5 top-0 left-0 brightness-150" />
            <div class="z-10 px-4 flex w-full h-full items-center items-center gap-2">
                <div class="z-10 relative w-10 h-10">
                    <svg class="w-full h-full" viewBox="0 0 100 100">
                        <!-- Background circle -->
                        <circle
                            class="text-gray-700 stroke-current"
                            stroke-width="5"
                            
                            cx="50"
                            cy="50"
                            r="40"
                            fill="transparent"
                        ></circle>
                        <!-- Progress circle -->
                        <circle
                            class="text-indigo-500  progress-ring__circle stroke-current"
                            stroke-width="8"
                            stroke-linecap="round"
                            cx="50"
                            cy="50"
                            r="40"
                            fill="transparent"
                            :stroke-dashoffset="400 - (currentStep / 6 * 400)"
                        ></circle>
                    </svg>
                    <div class="absolute inset-0 flex items-baseline justify-center mt-2">
                        <span class="text-text-primary font-medium">{{ getCurrentStep() }}</span>
                        <span class="text-text-secondary text-xs">/5</span>
                    </div>
                </div>
                <div class="z-10 flex flex-col justify-center flex-1">
                    <h1 class="text-text-primary text-sm">{{ getStepName(currentStep) }}</h1>
                    <h2 class="text-text-secondary text-xs">Next: {{ getStepName(currentStep + 1) }}</h2>
                </div>
                <div id="nav-icon3" @click="toggleDropDown()" class="ml-4 cursor-pointer"
                :class="[
                    showDropDown ? 'open' : ''
                ]">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div v-if="showDropDown" class="w-full bg-gray-950 border-t border-gray-800 fixed inset-y-[58px] px-4 py-8 flex flex-col gap-6 items-start"
        :class="[showDropDown ? 'h-52' : 'h-0']">
            <img class="h-6 w-auto mb-" src="../../assets/logos/refrag-horz-fill.svg" alt="Refrag" />
            <div class="flex flex-col gap-1">
                <h1 class="text-text-primary text-xl font-medium">Onboarding</h1>
                <h2 class="text-text-secondary text-xs">Setup your account in a few steps</h2>
            </div>
            <div class="flex w-full">
                <h2 class="text-text-secondary text-sm flex-1">{{ user?.email }}</h2>
                <p @click="logout" class="text-text-secondary text-sm cursor-pointer underline">Sign Out</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/solid";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { ref } from "vue";

const auth = useAuth();
const user = ref(auth.user());
const showDropDown = ref(false);

const props = defineProps({
    currentStep: Number
});

function logout() {
    auth.logout({
        makeRequest: true,
        redirect: { name: "Login" },
    });
}

function toggleDropDown() {
    showDropDown.value = !showDropDown.value;
}

function getCurrentStep() {
    if (props.currentStep >= 6) {
        return 6;
    }
    return props.currentStep + 1;
}

function getStepName(step) {
    switch (step) {
        case 0:
            return "Welcome";
        case 1:
            return "Connect Accounts";
        case 2:
            return "Match History";
        case 3:
            return "Rating";
        case 4:
            return "Subscribe";
        case 5:
            return "Play Now";
        default:
            return "Welcome";
    }
}

</script>

<style scoped>
.progress-ring__circle {
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

/* Icon 1 */

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 1.5px;
  width: 100%;
  background: #ABADD2;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 4px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 12px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

</style>