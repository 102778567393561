<template>
  <Dashboard>
    <div v-if="loading">
      <svg
        width="64"
        height="64"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="animate-spin"
      >
        <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
        <path
          d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
          stroke="#4E54C8"
          stroke-width="2"
        />
      </svg>
    </div>
    <div v-else class="flex flex-col gap-8 w-full">
      <div class="flex gap-3 items-center">
        <div class="flex flex-col gap-3 flex-1 items-start">
          <button
            @click="router.push({ name: 'RoutinesPage' })"
            class="bg-gray-800 text-text-primary text-xs px-3 py-2.5 rounded-lg flex gap-2 items-center hover:bg-gray-700 duration-300"
          >
            <img src="../../assets/icons/leftarrow.svg" alt="back" class="w-3 h-3" />
            Back to Routines
          </button>
          <div class="flex gap-4 w-full items-center">
            <div class="flex flex-col gap-3 flex-1">
              <h1 class="text-4xl font-medium text-text-primary">{{ bootcamp?.name }}</h1>
              <p class="text-text-secondary text-sm font-light">
                {{ bootcamp?.description }}
              </p>
            </div>
            <div
              @click="togglePublishModal()"
              class="px-3 py-2.5 bg-primary-600 hover:bg-primary-500 duration-300 text-text-primary text-xs font-medium rounded-lg cursor-pointer"
            >
              Review & {{ getPublishButtonText() }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-6 flex-col lg:flex-row">
        <div class="flex flex-col gap-[2px] flex-[8] overflow-hidden rounded-lg">
          <h1
            class="text-[20px] py-4 px-6 flex items-center gap-3 font-medium text-text-primary tracking-[0.5px] bg-gray-900"
          >
            Segments
          </h1>
          <Container
            orientation="vertical"
            @drop="onDrop"
            class="divide-y-2 divide-gray-950"
          >
            <Draggable v-for="(item, i) in tasks" :key="item.id">
              <div
                @click="selectSegment(item)"
                class="item cursor-pointer py-4 pl-1 pr-6 flex items-center gap-1 border-l select-none"
                :key="item"
                :class="[
                  selectedSegment?.id === item.id
                    ? 'bg-gray-800 border-primary-500'
                    : 'bg-gray-900 border-gray-900',
                ]"
              >
                <img
                  src="../../assets/icons/draggable.svg"
                  alt="segment"
                  class="w-6 h-6 select-none"
                />
                <div
                  class="flex items-center justify-center h-10 w-10 rounded-full bg-gray-800 border border-gray-700 text-sm text-text-primary"
                >
                  #{{ i + 1 }}
                </div>
                <div class="flex flex-col justify-center gap-1 pl-2">
                  <h2 class="text-text-primary">{{ item.name }}</h2>
                  <div class="text-text-secondary flex items-center text-xs gap-2">
                    <h2>{{ item.cs_map?.friendly_name }}</h2>
                    <span class="text-text-disabled">•</span>
                    <h2>{{ getModNameFromId(item) || item.mod }}</h2>
                    <span class="text-text-disabled">•</span>
                    <h2>{{ item.estimated_duration_in_minutes || 0 }} minutes</h2>
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>

          <div class="flex gap-3 items-center pt-6">
            <div class="bg-gray-800 h-px flex-1" />
            <button
              @click="createNewSegment()"
              class="h-8 w-8 bg-gray-800 rounded-lg flex items-center justify-center hover:bg-gray-700 duration-300"
            >
              <PlusIcon class="text-text-primary h-4 w-4" />
            </button>
            <div class="bg-gray-800 h-px flex-1" />
          </div>
        </div>
        <div class="flex flex-col gap-12 flex-[4]">
          <div class="flex flex-col gap-[2px]">
            <div class="flex items-center gap-3 bg-gray-900 py-4 px-6 rounded-t-lg">
              <h1
                class="text-[20px] flex items-center font-medium text-text-primary tracking-[0.5px] flex-1"
              >
                {{ selectedSegment.name }}
              </h1>
              <div
                @click="deleteSegment(selectedSegment)"
                class="cursor-pointer h-8 w-8 rounded-md bg-gray-800 flex items-center justify-center hover:bg-gray-700 duration-300"
              >
                <img src="../../assets/icons/trash.svg" />
              </div>
              <div
                @click="updateSegment(selectedSegment)"
                class="cursor-pointer h-8 px-2 text-xs text-text-primary rounded-lg bg-primary-600 flex items-center justify-center hover:bg-primary-500 duration-300"
              >
                Save
              </div>
            </div>

            <div class="p-6 flex flex-col gap-6 bg-gray-900 rounded-b-lg">
              <div class="flex flex-col">
                <h1 class="text-text-primary text-sm pb-1">Title</h1>
                <input
                  v-model="selectedName"
                  class="bg-gray-800 text-text-primary tracking-[0.5px] flex-1 rounded-lg"
                />
              </div>

              <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
                <h1 class="text-text-primary text-sm pb-1">Map</h1>
                <MenuButton
                  class="relative w-full disabled:bg-gray-400 inline-flex items-center p-3 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
                >
                  <div class="flex-1 text-left font-light">
                    {{ selectedMap?.friendly_name }}
                  </div>
                  <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
                </MenuButton>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1 flex flex-col px-4">
                      <MenuItem
                        :key="map"
                        v-for="map in maps"
                        v-slot="{ active }"
                        @click="updateActiveMap(map)"
                      >
                        <a
                          href="#"
                          :class="[
                            active ? 'text-text-primary' : 'text-text-secondary',
                            'group flex items-center py-2 text-sm w-full justify-start',
                          ]"
                        >
                          {{ map?.friendly_name }}
                          <CheckIcon
                            v-if="map.name === activeMap"
                            class="h-5 w-5 ml-auto text-primary-400"
                          />
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>

              <div class="w-full h-px bg-gray-800" />

              <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
                <h1 class="text-text-primary text-sm pb-1">Task Type</h1>
                <MenuButton
                  class="relative w-full disabled:bg-gray-400 inline-flex items-center p-3 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
                >
                  <div class="flex-1 text-left font-light">
                    {{ selectedMod?.friendly_name }}
                  </div>
                  <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
                </MenuButton>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1 flex flex-col px-4">
                      <MenuItem
                        :key="mod"
                        v-for="mod in mods"
                        v-slot="{ active }"
                        @click="updateActiveMod(mod)"
                      >
                        <a
                          href="#"
                          :class="[
                            active ? 'text-text-primary' : 'text-text-secondary',
                            'group flex items-center py-2 text-sm w-full justify-start',
                          ]"
                        >
                          {{ mod?.friendly_name }}
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>

              <Combobox
                v-if="selectedMod?.name === 'CreatorStudio'"
                class="flex-1"
                as="div"
                v-model="selectedCustomSet"
              >
                <div class="relative">
                  <ComboboxLabel
                    class="flex text-sm text-nowrap items-center rounded-md text-text-secondary border-0 bg-gray-800 px-3 h-12 shadow-sm ring-1 ring-inset ring-gray-700 focus:ring-2 focus:ring-inset focus:ring-primary-600"
                  >
                    Custom Set:
                    <ComboboxInput
                      class="appearance-none w-full bg-gray-800 border-0 ring-0 focus:ring-0 text-text-primary sm:text-sm sm:leading-6 font-medium"
                      :displayValue="(set) => set?.title"
                      @change="updateCustomSets($event.target.value)"
                      placeholder="Select a custom set"
                    />
                    <ComboboxButton
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-3 focus:outline-none"
                    >
                      <img
                        src="../../assets/icons/dropdown.svg"
                        class="text-gray-400"
                        aria-hidden="true"
                      />
                    </ComboboxButton>
                  </ComboboxLabel>

                  <ComboboxOptions
                    class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-gray-800 focus:outline-none sm:text-sm"
                  >
                    <ComboboxOption
                      v-for="set in customSets"
                      :key="set"
                      :value="set"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                          selected
                            ? 'text-text-primary bg-gray-800'
                            : 'text-text-secondary hover:bg-gray-800',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold text-text-primary',
                          ]"
                        >
                          {{ set.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>

              <div class="w-full h-px bg-gray-800" />

              <div class="flex flex-col">
                <h1 class="text-text-primary text-sm pb-2">Completion Type</h1>
                <div class="flex flex-row bg-gray-800 p-1 rounded-md">
                  <div
                    @click="updateCompletionType('complete')"
                    class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                    :class="[
                      selectedCompletionType === 'complete'
                        ? 'bg-gray-700 text-text-primary'
                        : 'text-text-secondary bg-gray-800',
                    ]"
                  >
                    Completion
                  </div>
                  <div
                    @click="updateCompletionType('time')"
                    class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                    :class="[
                      selectedCompletionType === 'time'
                        ? 'bg-gray-700 text-text-primary'
                        : 'text-text-secondary bg-gray-800',
                    ]"
                  >
                    Time
                  </div>
                  <div
                    @click="updateCompletionType('kills')"
                    class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                    :class="[
                      selectedCompletionType === 'kills'
                        ? 'bg-gray-700 text-text-primary'
                        : 'text-text-secondary bg-gray-800',
                    ]"
                  >
                    Kills
                  </div>
                  <div
                    @click="updateCompletionType('arenas')"
                    class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                    :class="[
                      selectedCompletionType === 'arenas'
                        ? 'bg-gray-700 text-text-primary'
                        : 'text-text-secondary bg-gray-800',
                    ]"
                  >
                    Arenas
                  </div>
                </div>
              </div>

              <div v-if="selectedCompletionType !== 'complete'" class="flex flex-col">
                <h1 class="text-text-primary text-sm pb-2">Completion Value</h1>
                <div class="relative rounded-md shadow-sm flex flex-col">
                  <input
                    type="number"
                    v-model="selectedCompletionValue"
                    class="bg-gray-800 text-text-primary text-sm rounded-lg p-2"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
                  >
                    <span class="text-gray-200 sm:text-sm"
                    v-if="selectedCompletionType === 'time'"
                      >seconds</span
                    >
                    <span class="text-gray-200 sm:text-sm"
                    v-else-if="selectedCompletionType === 'kills'"
                      >kills</span
                    >
                    <span class="text-gray-200 sm:text-sm"
                    v-else-if="selectedCompletionType === 'arenas'"
                      >arenas</span
                    >
                  </div>
                </div>
              </div>

              <div class="w-full h-px bg-gray-800" />
              <div>
                <h1 class="text-text-primary text-sm pb-2"
                  >Estimated Duration</h1
                >
                <div class="relative rounded-md shadow-sm flex flex-col">
                  <input
                    type="number"
                    v-model="selectedDuration"
                    class="bg-gray-800 text-text-primary text-sm rounded-lg p-2"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
                  >
                    <span class="text-gray-200 sm:text-sm" id="estimated_duration-minutes"
                      >minutes</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    @close="showServerModal = false"
  />
  <ModalWindow :open="showPublishModal">
    <div class="flex flex-col gap-6">
      <div class="flex gap-3 items-center">
        <h1
          class="text-text-primary text-[24px] font-semibold flex gap-3 items-center flex-1"
        >
          Review & {{ getPublishButtonText() }}
        </h1>
        <XIcon
          @click="togglePublishModal"
          class="h-6 w-6 cursor-pointer text-text-secondary"
        />
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="text-text-primary flex gap-2 items-center">Title <span class="text-text-secondary text-xs">(required)</span></h1>
        <input
          v-model="bootcampName"
          placeholder="Enter title of your routine."
          class="bg-gray-800 text-text-primary rounded-lg p-2 text-sm"
        />
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="text-text-primary flex gap-2 items-center">Description <span class="text-text-secondary text-xs">(required)</span></h1>
        <textarea
          v-model="bootcampDescription"
          placeholder="Enter more details about your routine."
          rows="5"
          class="bg-gray-800 text-text-primary rounded-lg p-2 text-sm"
        />
      </div>

      <button
        :disabled="!bootcampName || !bootcampDescription"
        @click="publishRoutine()"
        class="bg-primary-600 text-text-primary px-4 py-2 rounded-lg hover:bg-primary-500 w-full duration-300 disabled:bg-gray-400"
      >
        {{ getPublishButtonText() }} Routine
      </button>
    </div>
  </ModalWindow>
</template>

<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import StartServerModal from "../../components/StartServerModal.vue";
import ModalWindow from "../../components/ModalWindow.vue";
import axios from "axios";
import { ref, reactive, computed, getCurrentInstance, watch } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import { Container, Draggable } from "vue3-smooth-dnd";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Combobox,
  ComboboxButton,
  ComboboxLabel,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
} from "@headlessui/vue";
import { useStore } from "vuex";
import { ChevronDownIcon, PlusIcon, XIcon, CheckIcon } from "@heroicons/vue/solid";

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const user = ref(auth.user());
const store = useStore();
const bootcamp = ref(null);
const loading = ref(true);
const serverInfo = ref();
const showServerModal = ref(false);
const activeServer = computed(() => store.getters.activeServer);
const websocket = ref(null);
const subscription = ref(null);
const instance = getCurrentInstance();
const launchLoading = ref(false);
const launchSuccess = ref(false);
const tasks = ref([]);
const selectedSegment = ref(null);
const selectedMap = ref(null);
const selectedMod = ref(null);
const selectedCompletionType = ref(null);
const selectedCompletionValue = ref(null);
const selectedDuration = ref(null);
const selectedName = ref(null);
const selectedDescription = ref(null);
const showPublishModal = ref(false);
const bootcampName = ref(null);
const bootcampDescription = ref(null);
const searchInput = ref("");
const customSets = ref([]);
const selectedCustomSet = ref(null);

function getBootcamp() {
  loading.value = true;
  axios({
    url: `/task_lists/${route.params.slug}`,
    method: "GET",
    params: {
      is_exam: false,
    },
  }).then((response) => {
    loading.value = false;
    bootcamp.value = response.data;
    updateNameAndDescription();
    loadTasks();
    selectSegment(tasks.value[0]);
  });
}

function updateNameAndDescription() {
  if (bootcamp.value.name !== 'User Routine') {
    bootcampName.value = bootcamp.value.name;
  }
  if (bootcamp.value.description !== 'This is a user routine') {
    bootcampDescription.value = bootcamp.value.description;
  }
}

function togglePublishModal() {
  showPublishModal.value = !showPublishModal.value;
}

const maps = computed(() => {
  return selectedMod.value?.cs_maps
                           .filter((map) => map.cs2)
                           .sort((a, b) => a.name.localeCompare(b.friendly_name))
                           || [];
});

const mods = computed(() => {
  return store.state.modInfo.mods.filter((mod) => mod.cs2 && mod.playable && mod.taskable);
});

function updateActiveMod(mod) {
  if (!mod.id) {
    selectedMod.value = store.state.modInfo.mods.find((m) => m.name.toLowerCase() === mod.toLowerCase());
  } else {
    selectedMod.value = mod;
  }
  if (selectedMod.value?.name.toLowerCase() === "creatorstudio") {
    getCustomSets();
    getCurrentSet();
  }
}

function getPublishButtonText() {
  return bootcamp.value.status === "published" ? "Update" : "Publish";
}

function updateActiveMap(map) {
  selectedMap.value = map;
  if (selectedMod.value?.name === "CreatorStudio") {
    getCustomSets();
  }
}

function getCurrentSet() {
  if (!selectedSegment.value.subject_id) {
    searchInput.value = "";
    selectedCustomSet.value = null;
    return;
  }

  axios({
    method: "get",
    url: `/custom_sets/${selectedSegment.value.subject_id}`,
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  }).then((res) => {
    selectedCustomSet.value = res.data;
  });
}

function updateCompletionType(type) {
  selectedCompletionType.value = type;
}

function getCustomSets() {
  axios({
    method: "get",
    url: `/custom_sets`,
    params: {
      "filters[map]": selectedMap.value.name,
      search_input: searchInput.value,
      per_page: 10,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  }).then((res) => {
    customSets.value = res.data.custom_sets;
  });
}

function updateCustomSets(value) {
  searchInput.value = value;
  getCustomSets();
}

watch(
  () => selectedCustomSet.value,
  () => {
    searchInput.value = selectedCustomSet.value?.title;
  }
);

function loadTasks() {
  bootcamp.value.sections.forEach((section) => {
    section.tasks.forEach((task) => {
      tasks.value.push(task);
    });
  });
  if (tasks.value.length === 0) {
    createNewSegment();
  }
}

function onDrop(dropResult) {
  tasks.value = applyDrag(tasks.value, dropResult);
  axios({
    method: "put",
    url: `/task_lists/${bootcamp.value.slug}/update_tasks_order`,
    data: {
      task_ids: tasks.value.map((t) => t.id),
    },
  });
}

function createNewSegment() {
  let mod = store.state.modInfo.mods.find((m) => m.name.toLowerCase() === "crossfire");
  axios({
    method: "post",
    url: `/tasks`,
    data: {
      task_list_id: bootcamp.value.id,
      task_list_section_id: bootcamp.value.sections[0].id,
      name: `Segment ${tasks.value.length + 1}`,
      cs_map_id: maps.value[0]?.id || 1,
      mod_id: mod.id,
      estimated_duration_in_minutes: 5,
      completion_type: "complete",
      step: tasks.value.length + 1,
    },
  })
    .then((res) => {
      tasks.value.push(res.data);
      selectSegment(res.data);
    })
    .catch((err) => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: err.response.data.message,
      });
    });
}

function deleteSegment(task) {
  if (tasks.value.length === 1) {
    store.commit("showMessage", {
      messageType: "error",
      messageText: `You can't delete the last segment!`,
    });
    setTimeout(() => {
      store.commit("hideMessage");
    }, 1500);
    return;
  }
  axios({
    method: "delete",
    url: `/tasks/${task.id}`,
  }).then(() => {
    tasks.value = tasks.value.filter((t) => t.id !== task.id);
    selectSegment(tasks.value[0]);
  });
}

function getModCustomSetId() {
  // catch if the selected mod is creator studio to allow custom set selection
  if (selectedMod.value.name.toLowerCase() === "creatorstudio") {
    return selectedCustomSet.value?.id;
  }

  if (selectedMod.value.is_custom_set) {
    return store.state.siteSettings.find((s) => s.name === `${selectedMap.value.name}_${selectedMod.value.name.toLowerCase()}_set_id`).value;
  } else {
    return null
  }
}

function getMod() {
  if (selectedMod.value.is_custom_set) {
    return store.state.modInfo.mods.find((m) => m.name.toLowerCase() === "creatorstudio");
  } else {
    return selectedMod.value;
  }
}

function getModNameFromId(task) {
  const setting = store.state.siteSettings.find((s) => s.value === task.subject_id);
  if (setting?.value) {
    return setting.name.split("_")[2];
  }

  return null;
}

function updateSegment(task) {
  axios({
    method: "put",
    url: `/tasks/${task.id}`,
    data: {
      name: selectedName.value,
      cs_map_id: selectedMap.value.id,
      mod_id: getMod()?.id,
      estimated_duration_in_minutes: selectedDuration.value,
      completion_value: selectedCompletionValue.value,
      completion_type: selectedCompletionType.value,
      subject: getModCustomSetId() || null,
    },
  }).then((res) => {
    store.commit("showMessage", {
      messageType: "success",
      messageText: `Segment updated!`,
    });
    setTimeout(() => {
      store.commit("hideMessage");
    }, 1500);
    tasks.value = tasks.value.map((t) => {
      if (t.id === res.data.id) {
        return res.data;
      }
      return t;
    });
  });
}

function publishRoutine() {
  let published = bootcamp.value.status === "published";
  axios({
    method: "put",
    url: `/task_lists/${bootcamp.value.id}`,
    data: {
      name: bootcampName.value,
      description: bootcampDescription.value,
      status: "published",
    },
  }).then(() => {
    if (published) {
      store.commit("showMessage", {
        messageType: "success",
        messageText: `Routine updated!`,
      });
    } else {
      store.commit("showMessage", {
        messageType: "success",
        messageText: `Routine published!`,
      });
    }
    router.push({ name: "RoutinePage", params: { slug: bootcamp.value.slug } });
  });
}

function selectSegment(task) {
  // Check to make sure that the selected subject ID is not a certain game mode
  let settingsMod = store.state.siteSettings.find((s) => s.value === task.subject_id);
  if (settingsMod?.value) {
    task.mod = settingsMod.name.split("_")[2];
  }

  selectedSegment.value = task;
  updateActiveMap(task.cs_map);
  updateActiveMod(task.mod);
  selectedCompletionType.value = task.completion_type;
  selectedDuration.value = task.estimated_duration_in_minutes;
  selectedCompletionValue.value = task.completion_value;
  selectedName.value = task.name;
}

function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;

  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
}

getBootcamp();
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
